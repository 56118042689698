import React from "react"

import Layout from "components/Layout"
import { Confirmation } from "page_components/contact"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} seo={{ title: "Контакт підтвердження" }}>
      <Confirmation />
    </Layout>
  )
}

export default NotFoundPage
